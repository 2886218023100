// @import '~bootstrap-css-only/css/bootstrap.min.css';
// @import 'assets/styles/Antd/antd.cleanui';
// // @import 'assets/styles/CleanUI/cleanui';
// // @import 'assets/styles/Bootstrap/bootstrap.cleanui';
// // @import 'assets/styles/Chartist/chartist.cleanui';
// // @import 'assets/styles/Nprogress/nprogress.cleanui';
// // @import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
// // @import '~c3/c3.min.css';
.text-primary{
  color:#58CCC5 !important;
}

.ant-radio-inner {
  border-color: #59B6AD !important;
}


.ant-card {
  box-shadow:0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)!important;
  .ant-card-head{
    background:#58CCC5;
    color:#fff;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.25rem;	
    .ant-card-head-title{
        padding:14px 0px;
    }
    .ant-btn{
      background: transparent;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
      border: 1px solid white;
    }
  }
}
.ant-btn:not(.ant-btn-icon-only) {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.flex-flow-column{
  flex-flow:column !important; ;
}
.highlight {
  background-color: #f2f4f8;
}
.utils__content {
  max-width: 100%;
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}
.site-card-wrapper {
  background: #ececec;
  padding: 30px;
}

.ant-btn-primary {
  color: #fff;
  background: #59B6AD;
  border-color: #59B6AD;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}


.ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    width: 120px;
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background:transparent !important;
  }
  body .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background:rgba(67, 161, 152,0.5) !important;
  }
  .ant-form-item{
    margin-top: 0px !important;
  }

  .ant-form-item-label{
    label{
  font-weight: 600;
    }
  }
  .ant-menu-submenu-popup {
    .ant-menu-sub{
    background:#58CCC5 !important;
    .ant-menu-item {
      a{
      color: rgba(255,255,255,0.8) !important;
    }
    &.ant-menu-item-active{
      a{
        color: rgba(255,255,255,1) !important;
      }
    }
      
    }
    
    }
    }

    
.form-item-flex-col{
  .ant-form-item-control{
    width: 100%;
    max-width: 100%;
  }
  .ant-form-item-control-input-content{
    display:flex;
    flex-direction: column;
    align-items: center;
    .ant-upload-picture-card-wrapper{
      display:flex;
      justify-content:center;
    }
  }
}

.otp-input-wrapper {
  width: 240px;
  text-align: left;
  display: inline-block;
}
.otp-input-wrapper input {
  padding: 0;
  width: 264px;
  font-size: 22px;
  font-weight: 600;
  color: #3e3e3e;
  background-color: transparent;
  border: 0;
  margin-left: 12px;
  letter-spacing: 39px;
  font-family: sans-serif !important;
}
.otp-input-wrapper input:focus {
  box-shadow: none;
  outline: none;
}
.otp-input-wrapper svg {
  position: relative;
  display: block;
  width: 240px;
  height: 2px;
}

.ant-popover-buttons {
  display: flex;
  justify-content: space-evenly;
}

.ant-popover-buttons button {
  width : 30%
}


@media screen and (min-width: 100px) and (max-width: 765px) {
	.videoPaddingSet {
		padding-bottom: 6% !important;
	}

}


